@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

$material-design-icons-font-path: '../fonts/material-icons/' !default;
$fa-font-path:                    '../fonts/fontawesome-free' !default;

$body-color:                        #112b4a !default;
$link-color:                        #4a90e2 !default;
$text-muted:                        rgba(#374D67, .54) !default;

$primary:                           #1ba4e3 !default;
$primary-dark:                      darken($primary, 10%) !default;
$success:                           #7DC668 !default;
$dark:                              #343b4a !default;
$info:                              #5dd2bc !default;
$warning:                           #F5B666 !default;
$danger:                            #ff7076 !default;

$body-bg:                           #FAFBFE !default;
$light: $body-bg !default;

$facebook: #3B5998 !default;
$twitter: #00ACED !default;
$instagram: #E4405F !default;
$dribbble: #EA4C89 !default;

$h4-font-size: 1.3125rem !default;

$enable-shadows:                    true !default;
$gutter:                 1rem;

$mdc-theme-secondary:               $primary !default;

$dark-gray:                         #939FAD !default;
$light-gray:                        #ECEEF0 !default;

$theme-colors: (
  "blue": $link-color,
  "teal": #16BA71,
  "purple": #5163C5,
  "dark-gray": $dark-gray,
  "light-gray": $light-gray,
  "muted-light": #DBDFE4,
  "facebook": #3b5998,
  "twitter": #00aced,
) !default;

$font-size-base:                    .9375rem !default; // 15px
$font-family-base:                  'Montserrat', sans-serif;

$small-font-size:                   .8125rem !default;

$headings-color:                    rgba($body-color, .84) !default;

// $paragraph-margin-bottom:           .9375rem !default;
$bottom-spacing:                    0.9375rem * 2 !default;

$border-color:                      #E2E8EE !default;

$badge-font-size:                   .675rem !default;
$badge-font-weight:                 bold !default;
$badge-padding-y:                   .25rem !default;
$badge-padding-x:                   .25rem !default;
$badge-border-radius:               2px !default;

$breadcrumb-padding-y:              0 !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          rgba(#364C66, .24) !default;
$breadcrumb-active-color:           rgba(#364C66, .54) !default;

$table-border-color:                $border-color !default;
$table-cell-padding:                .35rem 1rem !default;
$table-accent-bg:                   $body-bg !default;
//

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$btn-font-weight:            600 !default;

$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .875rem !default;
$input-btn-font-size:         .875rem !default;


$input-btn-padding-y-sm:      .15rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      .65rem !default;

$input-btn-padding-y-lg:      .35rem !default;
$input-btn-padding-x-lg:      .85rem !default;
$input-btn-font-size-lg:      1rem !default;

//
$input-bg: $body-bg !default;
$input-border-color:                #DBE5EE !default;
$input-box-shadow:                  inset 0 1px 2px 0 rgba(66,71,112,.12) !default;

$input-placeholder-color:           #A6B0BC !default;
$input-focus-border-color:          $primary !default;

$input-group-addon-bg:              $input-bg !default;
$input-group-addon-color:           $dark-gray !default;

$navbar-height:                     56px !default;
$navbar-item-spacing:               1rem !default;
$navbar-nav-link-padding-x:         .75rem !default;

$navbar-light-color:                rgba(#384E68, .54) !default;
$navbar-light-active-color:         rgba(17,43,74,.84) !default;

$navbar-dark-color:                 rgba(white, .54) !default;

$mdk-drawer-width:                  256px !default;
$layout-mini-drawer-width:          64px !default;

$sidebar-spacing-x:                 1rem !default;
$sidebar-spacing-y:                 1rem !default;

$sidebar-heading-font-size:         .6875rem !default;
$sidebar-heading-letter-spacing:    .92px !default;
$sidebar-heading-font-weight:       bold !default;

$sm-icon-spacing-x:                 .625rem !default;
$sm-button-font-weight:             500 !default;
$sm-button-font-size:               .925rem !default;

$ssm-button-height:                 2rem !default;
$ssm-button-font-size:              .875rem !default;

$sidebar-light-bg:                  $body-bg !default;
$sidebar-light-heading-color:       rgba(#384E68,.54) !default;

$sm-light-button-color:             #384E68 !default;
$sm-light-open-bg:                  transparent !default;
$sm-light-icon-color:               $dark-gray !default;
$sm-light-hover-icon-color:         $dark-gray !default;
$sm-light-active-button-color:      $primary !default;
$sm-light-active-icon-color:        $primary !default;
$sm-light-toggle-color:             #BBBCBD !default;
$sm-light-hover-button-bg:          transparent !default;

$ssm-light-button-color:            $sm-light-button-color !default;
$sm-light-open-button-color:        #384E68 !default;
$sm-light-open-icon-color:          #384E68 !default;

$sidebar-light-container-border-color: $border-color !default;
$sidebar-light-border-color:        $border-color !default;

$sidebar-dark-container-border-color: $dark !default;
$sidebar-dark-heading-color:        rgba(white, .18) !default;
$sm-dark-button-color:              rgba($dark-gray, .84) !default;
$sm-dark-icon-color:                rgba(#A4AFBA, .5) !default;
$sm-dark-active-button-color:       rgba(white, .84) !default;
$sm-dark-active-icon-color:         white !default;
$sm-dark-hover-icon-color:          white !default;

$sm-dark-active-button-bg:                  #fff !default;
$sm-dark-activebuttonbg-button-color:       $primary !default;
$sm-dark-activebuttonbg-icon-color:         $primary !default;

$sm-toggle-font-size:               18px !default;
$sm-toggle-icon:                    "\e148" !default;
$sm-open-toggle-icon:               "\e15d" !default;

$dropdown-padding-y:                .625rem !default;
$dropdown-border-color:             $border-color !default;
$dropdown-border-radius:            5px !default;
$dropdown-box-shadow:               0 5px 11px 0 rgba(0,0,0,0.07) !default;

// $dropdown-link-color:               $dark-gray !default;
$dropdown-link-hover-color:         $primary !default;
$dropdown-link-hover-bg:            transparent !default;

$dropdown-link-active-color:        $primary !default;
$dropdown-link-active-bg:           transparent !default;

$dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x:           1.25rem !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1140px
) !default;


$card-cap-bg:                       $body-bg !default;
$card-border-color:                 #D5DDE6 !default;
$card-spacer-y:                     .75rem !default;
$card-spacer-x:                     1.25rem !default;

$list-group-item-padding-y:         1rem !default;
$list-group-border-color:           $border-color !default;

$pagination-color:                  #929EAC !default;
$pagination-border-width:           0 !default;
$pagination-line-height:            30px !default;
$pagination-bg:                     #ECEEF0 !default;
$pagination-active-bg:              $primary;

$pagination-disabled-bg:            rgba($pagination-bg, .5) !default;
$pagination-disabled-color:         rgba($pagination-color, .3) !default;

// tabs
$nav-tabs-border-width:             0 !default;
$nav-tabs-link-active-color:        $primary !default;
$nav-tabs-link-active-bg:           #fff !default;
$nav-tabs-link-active-border-color: $card-border-color !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Sidebar style guide (default)
@import 'sidebar-style-guide/sass/variables';

// MDK (default)
@import 'material-design-kit/src/variables';
